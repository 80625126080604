<template>
    <div id="ranking" :class="[$mq]" v-if="(categoriesExists && type == 'category') || type != 'category'">
        <h3 v-if="!multipleRanking" class="title">{{ title }}</h3>
        <h3 v-else class="title-container">
            <div class="title">{{ title }}</div>
            <div class="list-btns">
                <div class="icon percent" @click="changeList(1)" :class="{ active: listType == 1 }"></div>
                <div class="icon list" v-if="data && Object.values(data).length > 0" @click="changeList(2)" :class="{ active: listType == 2 }"></div>
            </div>
        </h3>
        <transition :name="list">
            <div key="1" class="content" v-if="!multipleRanking || listType == 1">
                <div v-if="top" class="column top" :class="{ full: columns == 1 }">
                    <div class="subtitle">{{ $t('analytics.ranking.top') }}</div>
                    <div class="content">
                        <div v-for="item in data_top" class="item" :key="item.id" :class="[{ disabled: item.score === false }, { loading: data == undefined }]">
                            <template v-if="item.score !== false">
                                <div class="meta">
                                    <div class="pos">{{ item.pos }}.</div>
                                    <div class="name" :title="item.name" v-html="item.name"></div>
                                    <div class="score">{{ item.score }} %</div>
                                </div>
                                <div class="bar">
                                    <div class="progress" :style="'width:' + item.score + '%'"></div>
                                </div>
                            </template>
                            <template v-else-if="data == null"> </template>
                            <template v-else>
                                <div class="nodata">{{ $t('analytics.ranking.nodata') }}</div>
                            </template>
                        </div>
                    </div>
                </div>

                <div v-if="bottom && columns == 2" class="column bottom">
                    <div class="subtitle">{{ $t('analytics.ranking.bottom') }}</div>
                    <div class="content">
                        <div v-for="item in data_bottom" class="item" :key="item.id" :class="{ disabled: item.score === false }">
                            <template v-if="item.score !== false">
                                <div class="meta">
                                    <div class="pos">{{ item.pos }}.</div>
                                    <div class="name" :title="item.name" v-html="item.name"></div>
                                    <div class="score">{{ item.score }} %</div>
                                </div>
                                <div class="bar">
                                    <div class="progress" :style="'width:' + item.score + '%'"></div>
                                </div>
                            </template>
                            <template v-else-if="data == null"> </template>
                            <template v-else>
                                <div class="nodata">{{ $t('analytics.ranking.nodata') }}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div key="2" class="all-items" v-else-if="multipleRanking || listType == 2">
                <div class="content">
                    <div v-for="(item, index) in dataSorted" class="item" :key="item.id" :class="{ disabled: item.score === false }">
                        <template v-if="item.score !== false">
                            <div class="meta">
                                <div class="pos">{{ index + 1 }}.</div>
                                <div class="name" :title="item.name" v-html="item.name"></div>
                                <div class="score">{{ item.score }} %</div>
                            </div>
                            <div class="bar">
                                <div class="progress" :style="'width:' + item.score + '%'"></div>
                            </div>
                        </template>
                        <template v-else-if="data == null"> </template>
                        <template v-else>
                            <div class="nodata">{{ $t('analytics.ranking.nodata') }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'Ranking',
    components: {},
    props: {
        // data: { type: Object },
        title: { type: String, default: '' },
        limit: { type: Number, default: 5 },
        top: { type: Boolean, default: true },
        bottom: { type: Boolean, default: true },
        multipleRanking: { type: Boolean, default: false },
        type: { type: [String, Boolean], default: false }
    },
    data() {
        return {
            listType: 1,
            list: 'slide-left',
            data: null
        }
    },
    computed: {
        dataSorted() {
            // sort by name
            return Object.values(this.data).sort((a, b) => {
                a.score - b.score
                return 0
            })
        },
        currentFilters() {
            return this.$store.getters['analyticsHome/getActiveFilters']
        },
        categoriesExists() {
            let exists = this.$store.getters['analyticsHome/getFilterCategories']()
            return exists.length > 0 ? true : false
        },
        data_top() {
            let result = []
            var count = 1
            for (var key in this.data) {
                var item = this.data[key]
                if (count <= this.limit) {
                    item.pos = count
                    result.push(item)
                    count++
                }
            }

            while (count <= this.limit) {
                var item = {
                    pos: count,
                    name: false,
                    score: false
                }
                result.push(item)
                count++
            }

            return result
        },
        data_bottom() {
            let result = []
            if (this.data) {
                var sorted_data = Object.values(this.data).sort(function (a, b) {
                    return a.score - b.score
                })

                var top_ids = []
                for (var key in this.data_top) {
                    top_ids.push(this.data_top[key].id)
                }

                var count = 1
                for (var key in sorted_data) {
                    var item = sorted_data[key]
                    if (count <= this.limit && !top_ids.includes(item.id)) {
                        item.pos = count
                        result.push(item)
                        count++
                    }
                }

                while (count <= this.limit) {
                    var item = {
                        pos: count,
                        name: false,
                        score: false
                    }
                    result.push(item)
                    count++
                }
            }

            return result
        },
        columns() {
            return this.data ? (Object.keys(this.data).length <= this.limit ? 1 : 2) : 1
        }
    },
    methods: {
        load() {
            let self = this
            if (this.type) {
                let params = { ...this.currentFilters }
                params.data.type = this.type
                this.$store.dispatch('analyticsHome/loadData', params).then(function (response) {
                    self.data = response
                })
            }
        },
        changeList(type) {
            this.listType = type
            if (type == 2) {
                this.list = 'slide-fade-left'
            } else {
                this.list = 'slide-fade-right'
            }
        }
    },
    created() {},
    watch: {
        currentFilters() {
            this.data = null
            this.load()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/* GO TO NEXT SLIDE */
.slide-fade-left-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-left-leave-active {
}
.slide-fade-left-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(30px);
    opacity: 0;
}
.slide-fade-right-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-right-leave-active {
}
.slide-fade-right-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-30px);
    opacity: 0;
}

#ranking {
    margin-top: 25px;
    height: 351px;

    .title {
        text-align: center;
        color: $color-primary-500;
        font-weight: bold;
        font-family: $text-bold !important;
    }
    .title-container {
        @include display-flex();
        width: 100%;

        .title {
            text-align: center;
            color: $color-primary-500;
            font-weight: bold;
            font-family: $text-bold !important;
            width: calc(100% - 60px);
            margin-left: 60px;
        }

        .list-btns {
            @include display-flex();
            margin-right: 10px;
            .icon {
                background-color: $color-white;
                width: 31px;
                height: 31px;
                cursor: pointer;

                &.percent {
                    background-image: url('~@/../public/img/percent.svg');
                    background-size: 20px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;

                    &.active {
                        background-image: url('~@/../public/img/percent_light.svg');
                        background-color: $color-primary-500;
                    }
                }

                &.list {
                    background-image: url('~@/../public/img/list_main.svg');
                    background-repeat: no-repeat;
                    background-size: 20px;
                    background-position: center center;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    &.active {
                        background-image: url('~@/../public/img/list_fff.svg');
                        background-color: $color-primary-500;
                    }
                }
                &:hover {
                    background-color: rgb(232, 227, 227);
                }
            }
        }
    }
    .all-items {
        width: 100%;
        overflow: hidden;
        padding-top: 10px;
        .content {
            height: 310px;
            overflow: auto;
            padding-top: 0px;
        }
        .item {
            @include background($color: $color-white);
            padding: 10px 15px;
            @include border-radius(4px);
            margin-bottom: 5px;
            height: 50px;
            &.disabled {
                width: 100%;
                height: 50px;
                .nodata {
                    @include background($color: $default-bg-color);
                    @include font-size($size: 'sm', $important: true);
                    margin-top: 6px;
                    color: #999;
                }

                &.loading {
                    padding: 0px;
                    .loading {
                        // animation loader gradient left to right color
                        animation: loading 15s ease infinite;
                        background: linear-gradient(90deg, rgba(173, 173, 173, 1) 0%, rgba(224, 222, 222, 1) 50%, rgba(236, 233, 239, 1) 100%);
                        width: 100%;
                        height: 50px;
                        position: relative;
                        bottom: 10px;
                    }
                    @keyframes loading {
                        0% {
                            background-position: 0% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                        100% {
                            background-position: 0% 50%;
                        }
                    }
                }
            }
            .meta {
                overflow: hidden;
                .pos {
                    @include font-size($size: 'sm', $important: true);
                    display: inline-block;
                    width: 23px;
                    font-weight: bold;
                    @include text-ellipsis();
                }
                .name {
                    display: inline-block;
                    width: calc(100% - 100px);
                    @include text-ellipsis();
                    @include font-size($size: 'sm', $important: true);
                }
                .score {
                    @include font-size($size: 'm', $important: true);
                    display: inline-block;
                    font-weight: bold;
                    color: $color-primary-500;
                    float: right;
                }
            }
            .bar {
                @include background($color: $default-bg-color);
                width: 100%;
                height: 7px;
                @include border-radius(4px);
                margin-top: 5px;

                .progress {
                    @include background($color: $color-primary-500);
                    height: 7px;
                    @include border-radius(4px);
                    transition: all 1s ease;
                }
            }
        }
    }
    .content {
        text-align: center;
        padding-top: 15px;
        overflow: hidden;
        .column {
            width: 50%;
            margin: 0 auto;
            display: inline-block;
            float: left;

            &.full {
                width: 100%;
            }

            .subtitle {
                text-align: center;
                text-transform: uppercase;
            }
            .content {
                padding: 10px;
                .item {
                    @include background($color: #fff);
                    padding: 10px 15px;
                    @include border-radius(4px);
                    margin-bottom: 5px;
                    height: 50px;
                    &.disabled {
                        @include background($color: $default-bg-color);

                        .nodata {
                            @include font-size($size: 'sm', $important: true);
                            margin-top: 6px;
                            color: #999;
                        }
                        &.loading {
                            padding: 0px;
                            animation: loading 2s ease infinite;
                            background: linear-gradient(90deg, rgba(249, 249, 249, 1) 0%, rgba(224, 222, 222, 1) 35%, rgba(236, 233, 239, 1) 68%);
                            background-size: 400% 400%;
                        }
                        @keyframes loading {
                            0% {
                                background-position: 0% 50%;
                            }
                            50% {
                                background-position: 100% 50%;
                            }
                            100% {
                                background-position: 0% 50%;
                            }
                        }
                    }
                    .meta {
                        overflow: hidden;
                        .pos {
                            @include font-size($size: 'sm', $important: true);
                            display: inline-block;
                            width: 20px;
                            font-weight: bold;
                            @include text-ellipsis();
                        }
                        .name {
                            display: inline-block;
                            width: calc(100% - 100px);
                            @include text-ellipsis();
                            @include font-size($size: 'sm', $important: true);
                        }
                        .score {
                            @include font-size($size: 'm', $important: true);
                            display: inline-block;
                            font-weight: bold;
                            color: $color-primary-500;
                            float: right;
                        }
                    }
                    .bar {
                        @include background($color: $default-bg-color);
                        width: 100%;
                        height: 7px;
                        @include border-radius(4px);
                        margin-top: 5px;

                        .progress {
                            @include background($color: $color-primary-500);
                            height: 7px;
                            @include border-radius(4px);
                            transition: all 1s ease;
                        }
                    }
                }
            }
            &.bottom {
                .content {
                    .item {
                        .meta {
                            .score {
                                color: $color-error-500 !important;
                            }
                        }
                        .bar {
                            .progress {
                                @include background($color: $color-error-500);
                            }
                        }
                    }
                }
            }
        }
    }

    &.portrait {
        .content {
            .column {
                width: 100%;
            }
        }
    }
}
</style>

<template>
    <div id="filters-analytics" :class="[$mq, 'filter-box']">
        <div class="filter-items">
            <!-- Date -->
            <div class="filter date">
                <div class="filter-ball"></div>
                <label>{{ $t('analytics.date') }}</label>
                <v-select
                    :class="{ filtered: currentFilters.type_date, changed: currentFilters.type_date && currentFilters.type_date != activeFilters.type_date }"
                    hide-details
                    class="select"
                    v-model="currentFilters.type_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.type_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.type_date == 'custom'">
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                    <div class="separator"></div>

                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>

            <!-- Groups -->
            <div class="filter" v-if="showGroups">
                <label>{{ $t('analytics.groups') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.groups && Object.values(currentFilters.groups).length > 0,
                        changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.groups"
                    :items="Object.values(optionsGroups)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.groups" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- Locations -->
            <div class="filter">
                <label>{{ $t('analytics.locations') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.locations && Object.values(currentFilters.locations).length > 0,
                        changed: JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.locations"
                    :items="Object.values(optionsLocations)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.locations" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- Category -->
            <div class="filter" v-if="optionsCategories && Object.values(optionsCategories).length > 0">
                <label>{{ $t('analytics.category') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.categories && Object.values(currentFilters.categories).length > 0,
                        changed: JSON.stringify(currentFilters.categories) != JSON.stringify(activeFilters.categories)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.categories"
                    :items="Object.values(optionsCategories)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.categories" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <div class="analytics-filters-actions">
            <div class="action">
                <Button
                    bType="cancel"
                    v-if="JSON.stringify(activeFilters) != JSON.stringify(emptyFilters)"
                    :bCallback="resetFilters"
                    bLabel="assets.filter.delete"
                >
                </Button>
            </div>

            <div class="action">
                <Button bType="filter" :bCallback="applyFilters" :bLabel="changesDetected ? 'assets.filter.filter' : 'assets.filter.refresh'"> </Button>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'FilterAnalyticsHome',
    data() {
        return {
            currentFilters: {
                type_date: 'yesterday'
            },
            emptyFilters: {
                type_date: 'yesterday'
            },
            activeFilters: {
                type_date: 'yesterday'
            },
            changesDetected: false,
            maxDate: true,
            minDate: true,
            interval: null
        }
    },
    computed: {
        optionsLocations() {
            return this.$store.getters['analyticsHome/getFilterLocations']()
        },
        optionsGroups() {
            return this.$store.getters['analyticsHome/getFilterGroups']()
        },
        optionsCategories() {
            return this.$store.getters['analyticsHome/getFilterCategories']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        showGroups() {
            let user = this.$store.getters['login/getLocalEmployee']
            let rol_id = typeof user.rol !== 'undefined' ? user.rol : -1
            let rol = this.$store.getters['employee/getRoles'](rol_id)
            let show = typeof rol !== 'undefined' && rol ? parseInt(rol.level) >= 4 : false
            return show
        }
    },
    methods: {
        applyFilters(first) {
            let definitiveFilters = {}

            // date
            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                    definitiveFilters.end_date = ''
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            // groups
            if (this.currentFilters.groups) {
                var filter = this.currentFilters.groups
                definitiveFilters.groups = []
                for (var index in filter) {
                    definitiveFilters.groups.push(filter[index].id)
                }
                definitiveFilters.groups = definitiveFilters.groups.toString()
            } else {
                definitiveFilters.groups = ''
            }

            // locations
            if (this.currentFilters.locations) {
                var filter = this.currentFilters.locations
                definitiveFilters.locations = []
                for (var index in filter) {
                    definitiveFilters.locations.push(filter[index].id)
                }
                definitiveFilters.locations = definitiveFilters.locations.toString()
            } else {
                definitiveFilters.locations = ''
            }

            // category
            if (this.currentFilters.categories) {
                var filter = this.currentFilters.categories
                definitiveFilters.categories = []
                for (var index in filter) {
                    definitiveFilters.categories.push(filter[index].id)
                }
                definitiveFilters.categories = definitiveFilters.categories.toString()
            } else {
                definitiveFilters.categories = ''
            }

            let self = this
            this.changesDetected = false
            let params = {
                type: 1,
                data: {
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    groups: definitiveFilters.groups,
                    locations: definitiveFilters.locations,
                    categories: definitiveFilters.categories,
                    refresh: first ? 0 : 1
                }
            }

            self.$store.commit('analyticsHome/setActiveFilters', params)
            params.data.type = 'total'
            self.$store.dispatch('analyticsHome/loadData', params).then(function (response) {
                self.data = response
            })
        },

        calcDate() {
            this.currentFilters.start_date ? (this.maxDate = moment(this.currentFilters.start_date).format('YYYY-MM-DD')) : true
            this.currentFilters.end_date ? (this.minDate = moment(this.currentFilters.end_date).format('YYYY-MM-DD')) : true
            this.currentFilters = { ...this.currentFilters }
        },

        resetFilters() {
            let self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.changesDetected = false
            this.$store.dispatch('analyticsHome/loadData', { type: 1, data: { start_date: 'yesterday' } }).then(function () {
                self.$overlay.hide()

                self.$emit('getFilterDay', self.activeFilters)
            })
        }
    },
    mounted() {
        this.applyFilters(true)
    },

    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                }
            },
            deep: true
        }
    },

    created() {},

    beforeDestroy() {}
}
</script>

<style lang="scss">
#filters-analytics {
    @include display-flex();
    @include justify-content(space-between);
    padding: 0;
    margin: 0 0 40px 0;
    height: auto;
    box-shadow: unset;
    background: unset;

    .filter-items {
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(flex-start);
        @include flex-direction(row);
        @include flex-wrap(wrap);
        height: auto;
        overflow: initial;
        padding-bottom: 0;

        .filter {
            height: auto;
            margin: 0 12px 0 0;
        }
    }

    .analytics-filters-actions {
        margin-top: 34px;
        height: auto;
        align-self: flex-start;

        .action {
            float: right;
        }
    }

    .tags-container {
        max-width: 250px;
    }

    &.portrait {
        padding: 8px;
        flex-wrap: wrap;
        .filter-items {
            padding: 0;
            .filter {
                margin: 0;
                width: 100%;

                label {
                    margin: 0;
                }
            }
        }

        .analytics-filters-actions {
            margin-top: 16px;
            width: 100%;
        }
    }
}
</style>

<template>
    <!-- <div id="content" class="analytics"> -->
    <div id="content" :class="[$mq, { noExpend: !isExpanded, analytics: isExpanded && $mq == 'desktop' }]">
        <div class="content full scrollbar">
            <!-- <div :class="{ content: isExpanded, noExpend: !isExpanded }"> -->
            <div class="filters">
                <AnalyticsFilter @getFilterDay="setDateFiltered"></AnalyticsFilter>
            </div>

            <div class="global">
                <div class="item">
                    <div class="meta">
                        <div class="name">{{ $t('analytics.ranking.title_score') }}</div>
                        <div class="score">{{ checklist_result.score ? checklist_result.score : 0 }} %</div>
                    </div>
                    <div class="bar">
                        <div class="progress" :style="'width:' + checklist_result.score + '%'"></div>
                    </div>
                </div>

                <div class="item">
                    <div class="meta">
                        <div class="name">
                            {{ $t('analytics.ranking.title_completed') }}
                        </div>
                        <div class="score">{{ checklist_result.answered }}</div>
                    </div>
                </div>
            </div>

            <div class="column">
                <Ranking :multipleRanking="true" type="locations" :title="$t('analytics.ranking.sites')" :limit="5" :top="true" :bottom="true"></Ranking>
            </div>
            <div class="column" v-if="showGroups">
                <Ranking :multipleRanking="true" type="groups" :title="$t('analytics.ranking.groups')" :limit="5" :top="true" :bottom="true"></Ranking>
            </div>
            <div class="column">
                <Ranking type="timeframes" :title="$t('analytics.ranking.timeframes')" :limit="5" :top="true" :bottom="true"></Ranking>
            </div>
            <div class="column">
                <Ranking type="template" :title="$t('analytics.ranking.templates')" :limit="5" :top="true" :bottom="true"></Ranking>
            </div>
            <div class="column">
                <Ranking type="category" :title="$t('analytics.ranking.category')" :limit="5" :top="true" :bottom="true"></Ranking>
            </div>
            <div class="column full" v-if="current_date != 'today' && current_date != 'yesterday'">
                <h3 class="title">{{ $t('analytics.ranking.date') }}</h3>

                <apexchart :options="chart.options" :series="chart.series"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import apexchart from 'vue-apexcharts'
import Ranking from '@/components/domain/analyticsHome/ranking'
import AnalyticsFilter from '@/components/domain/analyticsHome/filter'

export default {
    name: 'AnalyticsHome',
    components: { apexchart, Ranking, AnalyticsFilter },
    data() {
        return {
            dates: [
                {
                    id: 'today',
                    name: i18n.t('supervise.filter.today')
                },
                {
                    id: 'yesterday',
                    name: i18n.t('supervise.filter.yesterday')
                },
                {
                    id: 'week',
                    name: i18n.t('supervise.filter.week')
                },
                {
                    id: 'month',
                    name: i18n.t('supervise.filter.month')
                },
                {
                    id: 'quarter',
                    name: i18n.t('supervise.filter.quarter')
                }
            ],
            filtered_date: 'yesterday',
            current_date: 'yesterday'
        }
    },
    computed: {
        // checklist() {
        //     return this.$store.getters['analyticsHome/getData']
        // },
        visibleTools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },
        checklist_result() {
            // var total = 0
            // var count = 0
            // var score = 0
            // var answered = 0

            // if (this.checklist) {
            //   for (var key in Object.values(this.checklist.location)) {
            //     var item = Object.values(this.checklist.location)[key]
            //     total += parseInt(item.total)
            //     score += parseFloat(item.score)
            //     answered += parseInt(item.answered)
            //     count++
            //   }

            //   score = Math.round(100 * score / count) / 100
            // }

            let data = this.$store.getters['analyticsHome/getData']
            let total = typeof data.total !== 'undefined' && typeof data.total !== 'undefined' ? data.total : 0
            let score = typeof data.total !== 'undefined' && typeof data.score !== 'undefined' ? data.score : 0
            let answered = typeof data.total !== 'undefined' && typeof data.answered !== 'undefined' ? data.answered : 0

            var result = {
                total: total,
                score: score,
                answered: answered
            }

            return result
        },
        chart() {
            let values = []
            let labels = []

            if (this.checklist) {
                values = Object.values(this.checklist.date).map((a) => a.score)
                labels = Object.keys(this.checklist.date)
            }

            var chart = {
                options: {
                    chart: {
                        id: 'date-chart',
                        height: 400,
                        width: '100%',
                        type: 'line',
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#4163ca'],
                    stroke: {
                        width: 2
                    },

                    yaxis: {
                        title: {
                            text: i18n.t('analytics.ranking.score')
                        },
                        // min: 0,
                        // max: 100,
                        tickAmount: 5,
                        labels: {
                            formatter: function (value) {
                                return value + '%'
                            }
                        }
                    },
                    xaxis: {
                        categories: labels,
                        hideOverlappingLabels: true,
                        trim: true,
                        // range: 20,
                        labels: {
                            formatter: function (value) {
                                return moment(value).format('ddd DD/MM')
                            },
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    markers: {
                        size: 5,
                        hover: {
                            sizeOffset: 4
                        }
                    }
                },
                series: [
                    {
                        name: i18n.t('analytics.ranking.score'),
                        data: values
                    }
                ]
            }

            return chart
        },
        showGroups() {
            let user = this.$store.getters['login/getLocalEmployee']
            let rol_id = typeof user.rol !== 'undefined' ? user.rol : -1
            let rol = this.$store.getters['employee/getRoles'](rol_id)
            let show = typeof rol !== 'undefined' && rol ? parseInt(rol.level) >= 4 : false
            return show
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        }
    },
    methods: {
        setDateFiltered(date) {
            this.current_date = date.type_date
        },
        search(nameKey) {
            if (this.visibleTools) {
                for (var i = 0; i < this.visibleTools.length; i++) {
                    if (this.visibleTools[i] === nameKey) {
                        return this.visibleTools[i]
                    }
                }
            }
        },
        load() {
            //1,2,4,7
            var self = this
            // Mostrar la home directamente en labelling
            // Si la cuenta tiene alguna de estas herramientas muestra la home normal, si no la home es /supervise/label
            var tools = [1, 2, 4, 7]
            var hasOneAtLeast = false
            for (var i in tools) {
                if (this.search(tools[i]) != undefined) {
                    hasOneAtLeast = true
                }
            }
            // console.log(this.search(3))
            if ((this.$route.path == '/' || this.$route.path == '/home') && !hasOneAtLeast && this.search(3) != 'undefined') {
                self.$router.push(self.redirectPath('/supervise/label')).catch((err) => {
                    if (err.name == 'NavigationDuplicated') {
                        self.$router.push('/reset' + self.redirectPath('/supervise/label'))
                    }
                })
            } else {
                let self = this
                // self.$store
                //     .dispatch('analyticsHome/loadData', {
                //         type: 1,
                //         data: { start_date: 'yesterday' }
                //     })
                // .then(function (response) {
                self.$store
                    .dispatch('analyticsHome/loadFilters')
                    .then(function (response) {})
                    // })
                    .catch(function (error) {
                        console.error(error)
                    })
            }
            // this.updateFilter()
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        }
    },
    watch: {
        $route: 'load'
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.analytics {
    padding: 40px;
    width: calc(100% - 126px) !important;

    > .content {
        padding: 25px;
        @include border-radius(4px);
        background-color: $color-neutral-50 !important;
        width: 100% !important;
        overflow: hidden;
        // .filter {
        //   width: 100%;
        //   display: block;
        //   overflow: hidden;

        //   .select {
        //     width: 250px;
        //     float: right;
        //   }
        // }

        .filters {
            // z-index: 10;
            // position: relative;
        }

        .global {
            margin-bottom: 25px;

            .item {
                // @include background($color: #fff);
                padding: 20px 25px;
                @include border-radius(4px);
                margin-bottom: 5px;
                min-height: 85px;
                width: 33%;
                max-width: 325px;
                display: inline-block;
                margin: 5px 35px;

                &.disabled {
                    @include background($color: $default-bg-color);

                    .nodata {
                        @include font-size($size: 'sm', $important: true);
                        margin-top: 6px;
                        color: #999;
                    }
                }
                .meta {
                    overflow: hidden;
                    .name {
                        display: inline-block;
                        float: left;
                        // @include text-ellipsis();
                        @include font-size($size: 'sm', $important: true);
                        width: calc(100% - 120px);
                        font-weight: bold;
                        text-transform: uppercase;
                        // padding-top: 7.5px;
                    }
                    .score {
                        @include font-size($size: 'xl', $important: true);
                        display: inline-block;
                        font-weight: bold;
                        color: $color-primary-500;
                        float: right;
                        margin-top: -5px;
                    }
                }
                .bar {
                    @include background($color: $default-bg-color);
                    width: 100%;
                    height: 7px;
                    @include border-radius(4px);
                    margin-top: 10px;

                    .progress {
                        @include border-radius(4px);
                        background-color: $color-primary-500;
                        height: 7px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        .title {
            text-align: center;
            color: $color-primary-500;
            font-weight: bold;
            font-family: $text-bold;
            margin-top: 25px;
        }

        .column {
            display: block;
            float: left;
            width: 50%;
            margin: 0 auto;
            padding: 16px 16px;

            &.full {
                width: 100%;
            }
        }
    }
}

#content.noExpend {
    padding: 6px;
    > .content {
        padding: 40px;

        .title {
            text-align: center;
            color: $color-primary-500;
            font-weight: bold;
            font-family: $text-bold;
            margin-top: 25px;
        }

        .column {
            display: block;
            float: left;
            width: 50%;
            margin: 0 auto;
            padding: 16px 16px;

            &.full {
                width: 100%;
            }
        }
    }

    &.portrait {
        padding: 0;
        > .content {
            padding: 0;
            padding-left: 50px;
        }
    }
}

#content {
    > .content {
        .global {
            margin-bottom: 25px;

            .item {
                // @include background($color: #fff);
                padding: 20px 25px;
                @include border-radius(4px);
                margin-bottom: 5px;
                min-height: 85px;
                width: 33%;
                max-width: 325px;
                display: inline-block;
                margin: 5px 35px;

                &.disabled {
                    @include background($color: $default-bg-color);

                    .nodata {
                        @include font-size($size: 'sm', $important: true);
                        margin-top: 6px;
                        color: #999;
                    }
                }
                .meta {
                    overflow: hidden;
                    .name {
                        display: inline-block;
                        float: left;
                        // @include text-ellipsis();
                        @include font-size($size: 'sm', $important: true);
                        width: calc(100% - 120px);
                        font-weight: bold;
                        text-transform: uppercase;
                        // padding-top: 7.5px;
                    }
                    .score {
                        @include font-size($size: 'xl', $important: true);
                        display: inline-block;
                        font-weight: bold;
                        color: $color-primary-500;
                        float: right;
                        margin-top: -5px;
                    }
                }
                .bar {
                    @include background($color: $default-bg-color);
                    width: 100%;
                    height: 7px;
                    @include border-radius(4px);
                    margin-top: 10px;

                    .progress {
                        @include border-radius(4px);
                        background-color: $color-primary-500;
                        height: 7px;
                        transition: all 1s ease;
                    }
                }
            }
        }
    }

    &.portrait {
        > .content {
            .global {
                overflow: hidden;
                .item {
                    width: 100%;
                    max-width: 100%;
                    margin: 0;
                    padding: 8px 16px;
                    float: left;
                }
            }

            .column {
                overflow: hidden;
                width: 100%;
                padding: 0;
                #ranking {
                    height: auto;
                    .columm {
                    }
                }
            }
        }
    }
}
</style>
